import React, { Component, Fragment } from 'react'
import Loadable from 'react-loadable'
import zh_CN from 'antd/es/locale-provider/zh_CN'
import { LocaleProvider } from 'antd'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Loading from '@/components/loading'
import {isLoggedIn} from '@/utils/auth-service';
import './App.scss'

const MainLayout = Loadable({loader: () => import('@/components/mainLayout'), loading: Loading})
const Index = Loadable({loader: () => import('@/modules/index'), loading: Loading})
const Users = Loadable({loader: () => import('@/modules/users'), loading: Loading})
const Auth = Loadable({loader: () => import('@/modules/auth'), loading: Loading})
const Info = Loadable({loader: () => import('@/modules/information'), loading: Loading})
const Question = Loadable({loader: () => import('@/modules/questions'), loading: Loading})
const TestPage = Loadable({loader: () => import('@/modules/testPage'), loading: Loading})
const Setting = Loadable({loader: () => import('@/modules/setting'), loading: Loading})
const Message = Loadable({loader: () => import('@/modules/message'), loading: Loading})
const testManagement = Loadable({loader: () => import('@/modules/testManagement'), loading: Loading})
// 验证是否登录，未登录时跳转到登录页面
const PrivateDashboardRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render = { props => (
     isLoggedIn() ?
       <Fragment>
         <Layout />
         <div className={ document.body.clientWidth<=1366?`component-blocks`:'component-block'}  >
           <div className={`pd-30`}>
             <Component {...props} />
           </div>
         </div> 
       </Fragment> :
    <Redirect to={{ pathname: '/auth', state: { from: props.location } }}/>

  )} />
)

class App extends Component {
  componentDidMount(){
    var mainMenu = document.getElementsByClassName('component-block')[0];  
    console.log(this.props)
    if(mainMenu&&document.body.clientWidth<=1366){
        mainMenu.style.paddingLeft = 206+'px'
    }    
  }
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <BrowserRouter>
          <Switch>
            <PrivateDashboardRoute layout={MainLayout} path="/" exact component={Index} />
            <PrivateDashboardRoute layout={MainLayout} path="/users" component={Users} />
            <PrivateDashboardRoute layout={MainLayout} path="/info" component={Info} />
            <PrivateDashboardRoute layout={MainLayout} path="/question" component={Question} />
            <PrivateDashboardRoute layout={MainLayout} path="/setting" component={Setting} />
            <PrivateDashboardRoute layout={MainLayout} path="/test" component={TestPage} />
            <PrivateDashboardRoute layout={MainLayout} path="/messages" component={Message} />
            <PrivateDashboardRoute layout={MainLayout} path="/tests" component={testManagement} />
            <Route path="/auth" component={Auth} />
          </Switch>
        </BrowserRouter>
      </LocaleProvider>
    )
  }
}

export default App
