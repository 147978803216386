export function login() {
  sessionStorage.setItem('isLoggedIn', true);
}

export function isLoggedIn () {
  return sessionStorage.getItem("isLoggedIn");
}

export function logout() {
  sessionStorage.removeItem('isLoggedIn')
}

export function setUserInfoToLocal(userInfo) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

export function getUserInfoFromLocal() {
  return JSON.parse(sessionStorage.getItem('userInfo'))
}

export function removeUserInfoInLocal() {
  sessionStorage.removeItem('userInfo')
}

/*
* params:token
* */
export function token(params) {
  sessionStorage.setItem('token', params);
}

export function getToken () {
  return sessionStorage.getItem("token");
}

export function removetoken(params) {
  sessionStorage.removeItem('token');
}